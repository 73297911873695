@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-regular.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-medium.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-bold.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat/montserrat-semibold.woff2') format('woff2'),
        url('../fonts/montserrat/montserrat-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
