.modal-form {
  color: $white;

  @include breakpoint(small only) {
    padding: 48px 16px;
  }

  @include breakpoint(medium) {
    padding: 64px 30px;
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-weight: 700;
    color: $light-color;
    line-height: 1.2;

    @include breakpoint(small only) {
      font-size: 30px;
      margin-bottom: 40px;
    }

    @include breakpoint(medium) {
      font-size: 40px;
      margin-bottom: 50px;
    }
  }

  &__small-text {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .accept-form-text {
    a {
      color: $white;

      &:hover,
      &:focus {
        color: $accent-color;
      }
    }
  }

  &__btn {
    margin-top: 40px;
  }
}
