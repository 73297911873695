.breadcrumbs-sections {
  margin: 40px 0 60px;

  &__ul {
    display: flex;
    grid-gap: 30px;
    margin: 0;
    overflow-x: auto;

    font-size: 18px;
    color: $accent-color;
    white-space: nowrap;
    list-style: none;
  }

  &__link {
    color: inherit;
  }
}