.product-brands{
    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }

    &__list{
        display: grid;

        @include breakpoint(small only) {
            grid-gap: 16px;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 60px;
        }
        
        @include breakpoint(medium only) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(medium) {
            grid-gap: 50px;
            margin-bottom: 80px;
        }

        @include breakpoint(large) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__item{
        background-color:  $white;
        border-radius: 32px;
        border: 1px solid rgba($black, 0.24);
        transition: box-shadow 0.3s, border-color 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;        
        
        @include breakpoint(small only) {
            padding: 23px;
            height: 136px;
        }
    
        @include breakpoint(medium) {
            padding: 20px;
            min-height: 200px;
        }

        &:hover, &:focus{
            text-decoration: none;
            border-color: $accent-color;
            box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.2);
        }
    }

    &__small-title{
        display: block;
        font-size: 18px;
        font-weight: 600;
        
        @include breakpoint(small only) {
            margin-bottom: 24px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 30px;
        }
    }
}