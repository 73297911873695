.price-block {
  background-color: $white;

  @include breakpoint(small only) {
    padding: 20px 0 60px;
  }

  @include breakpoint(medium) {
    padding: 40px 0 80px;
  }

  &__title {
    @include breakpoint(small only) {
      margin-bottom: 32px;
    }

    @include breakpoint(medium) {
      margin-bottom: 50px;
    }
  }

  td {
    a {
      text-decoration: none;
      position: relative;
      border-bottom: 2px solid $accent-color;
      -webkit-transition: border-color 0.3s, color 0.3s;
      transition: border-color 0.3s, color 0.3s;

      &:hover,
      &:focus {
        color: $accent-color-hover;
        border-bottom-color: transparent;
      }
    }
  }
}
