.advantage-block{
    @include breakpoint(small only) {
        padding: 60px 0 44px;
    }

    @include breakpoint(medium) {
        padding: 80px 0 50px;
    }

    &__title{
        
        @include breakpoint(small only) {
            margin-bottom: 8px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 30px;
        }
    }
}