.main-text-content {
    background-color: $white;
  
    @include breakpoint(small only) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  
    @include breakpoint(medium) {
      padding-top: 36px;
      padding-bottom: 36px;
    }
  }
  