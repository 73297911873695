.top-block{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $main-bg;    

    @include breakpoint(small only) {
        padding-top: 39px;
        padding-bottom: 39px;
        background-position: top right;
        letter-spacing: 1px;
    }

    @include breakpoint(medium) {
        padding-top: 104px;
        padding-bottom: 104px;
        background-position: top right;
        min-height: 660px;
    }
    
    &__container{
        @include breakpoint(large) {
            max-width: 50%;            
        }
    }

    &__title{
        margin-bottom: 16px;
        font-weight: 700;

        @include breakpoint(medium down) {
            font-size: 36px;
        }

        @include breakpoint(medium) {
            font-size: 50px;
            
        }
    }

    &__small-title{
        display: block;
        color: $primary-color;
        color: $accent-color;
        margin-bottom: 24px;

        @include breakpoint(small down) {
            font-size: 18px;
            line-height: 22px;
        }

        @include breakpoint(medium) {
            font-size: 32px;
            line-height: 39px;
        }
    }

    &__small-title-semibold{
        font-weight: 600;
    }

    &__text{
        @include breakpoint(small) {
            margin-bottom: 40px;
        }

        @include breakpoint(medium) {
            margin-bottom: 50px;
        }

        @include breakpoint(large) {
            max-width: 510px;
        }
    }

    &__btn{
        @include breakpoint(small only) {
            margin-bottom: 40px;
        }

        @include breakpoint(medium) {
            margin-bottom: 80px;
        }
    }
}