.footer{
    background-color:$footer-bg;
    
    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0 60px;
    }

    p, &__info-text{
        font-size: 16px;
        color: $middle-gray;
        margin-bottom: 10px;
    }

    &__info-text{
        a{
            text-decoration: none;
            position: relative;
            border-bottom: 2px solid $accent-color;
            transition: border-color 0.3s, color 0.3s;
    
            &:hover, &:focus{
                color: $accent-color-hover;
                border-bottom-color: transparent;
            }
        }
    }

    span, a{
        color: $black;
    }
    
    a{
        &:hover, &:focus{
            text-decoration: none;
            color: $accent-color;
        }
    }

    ul{
        margin-left: 0;
    }

    li{
        list-style: none;
        margin-bottom: 16px;
    }

    &__link-without-line{
        border: none !important;
        
        &::after{
            content: none;
        }
    }

    &__title{
        font-weight: 700;
        display: block;
        margin-bottom: 20px;
        font-size: 18px;
    }

    &__info{
        margin-bottom: 20px;
    }

    &__inner{
        @include breakpoint(small only) {
            margin-bottom: 36px;
        }

        @include breakpoint(medium only) {
            margin-bottom: 64px;
            height: calc(100% - 64px) !important;
        }

        @include breakpoint(medium) {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }        
    }

    &__bottom{
        border-top: 1px solid rgba($black, 0.24);

        @include breakpoint(small only) {
            padding-top: 24px;
        }
    
        @include breakpoint(medium) {            
            padding-top: 30px;
            display: flex;
            align-items: center;
        }

        @include breakpoint(large) {
            margin-top: 60px;
        }
    }

    &__logo{
        flex-shrink: 0;
        
        @include breakpoint(small only) {
            margin-bottom: 16px;
            max-width: 156px;
        }
    
        @include breakpoint(medium) {
            margin-right: 17px;
            max-width: 200px;
        }
    }

    &__bottom-text{
        @include breakpoint(large) {
            max-width: 426px;
        }
    }

    &__messenger-link{
        display: flex;
        align-items: center;
        
        &:not(:last-child){
            margin-bottom: 19px;
        }
    }

    &__messenger-img{
        margin-right: 10px;
        max-width: 24px;
    }

    &__ya-title{
        @include breakpoint(small only) {
            display: none;
        }

        @include breakpoint(medium) {
            color: $black;
            margin-bottom: 13px;
            display: block;
        }
    }
}