.repair-window {
  background-color: $white;

  @include breakpoint(small only) {
    padding: 60px 0 20px;
  }

  @include breakpoint(medium) {
    padding: 80px 0 20px;
  }

  &__item {
    @include breakpoint(small only) {
      margin-bottom: 24px;
    }

    @include breakpoint(medium) {
      margin-bottom: 30px;
    }
  }
}
