.example-work {
  @include breakpoint(small only) {
    padding: 60px 0;
  }

  @include breakpoint(medium) {
    padding: 80px 0;
  }

  &-header {
    $hp: &;

    margin-bottom: 25px;
    @include breakpoint(medium) {
      margin-bottom: 60px;
    }

    &__row {
      margin: 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @include breakpoint(medium) {
        margin: -5px;
      }
    }

    &__col {
      @include breakpoint(medium) {
        margin: 5px;
      }

      @include breakpoint(small only) {
        & + #{$hp}__col {
          margin-top: 60px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 0px;
  }

  &__list {
    display: flex;
    // overflow-x: auto;
    padding-top: 26px;
    padding-bottom: 20px;

    @include breakpoint(medium down) {
      padding-bottom: 16px;
    }

    // &::-webkit-scrollbar-button {
    //   background-image: url("");
    //   background-repeat: no-repeat;
    //   width: 2px;
    //   height: 0px;
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: rgba(26, 9, 0, 0.16);
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: rgba(26, 9, 0, 0.32);
    // }

    // &::-webkit-resizer {
    //   background-image: url("");
    //   background-repeat: no-repeat;
    //   width: 2px;
    //   height: 0px;
    // }

    // &::-webkit-scrollbar {
    //   height: 4px;
    // }
  }

  .swiper-slide{
    @include breakpoint(small only) {
        width: auto;
    }

    @include breakpoint(medium) {
        width: auto;
    }
  }

  &__item {
    position: relative;
    border-radius: 40px;
    border-width: 4px 2px 0px 0px;
    border-style: solid;
    border-color: rgba($black, 0.16);
    border-radius: 40px;
    flex-shrink: 0;

    @include breakpoint(small only) {
      width: 250px;
      //min-height: 250px;

    //   &:not(:last-child) {
    //     margin-right: 24px;
    //   }
    }

    @include breakpoint(medium) {
      //width: 358px;
      //min-height: 356px;

    //   &:not(:last-child) {
    //     margin-right: 60px;
    //   }
    }

    // @include breakpoint(large) {
    //     width: calc(33.333% - (120px/3));
    // }
  }

  &__changer {
    position: absolute;
    background: url("../img/example-work/top-img.svg") no-repeat center;
    background-size: 100% auto;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    @include breakpoint(small only) {
      width: 194px;
      top: -21px;
      right: 27px;
      padding: 9px 33px;
    }

    @include breakpoint(medium) {
      padding: 16px 38px;
      width: 243px;
      top: -28px;
      right: 29px;
    }
  }

  &__text-changer {
    color: $light-color;
    padding: 1px 5px;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer;

    @include breakpoint(small only) {
      font-size: 14px;
    }

    @include breakpoint(medium) {
      font-size: 20px;
    }

    &_active {
      color: $accent-color;
      font-weight: 700;
      background-color: $white;
    }
  }
}
