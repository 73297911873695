.rating-feedback{
    line-height: 1;

    @include breakpoint(medium) {
        display: flex;
        align-items: center;
    }

    &_footer{
        display: block;

        .rating-feedback__left{
            margin-bottom: 10px;
        }
    }

    &__left{
        display: flex;
        align-items: center;
        margin-right: 15px;

        @include breakpoint(small only) {
            margin-bottom: 10px;
        }
    }

    &__list{        
        margin-right: 10px;
    }

    &__number{
        display: block;
        font-weight: 600;
        font-size: 18px;
        margin-right: 6px;
        line-height: 1;
        position: relative;
        top: 1px;
    }

    &__count{
        font-size: 18px;
        display: flex;
        align-items: center;
    }

    &__link{
        font-size: 18px;
        position: relative;
        text-decoration: none;
        margin-left: 6px;
        transition: color 0.3s;

        &:after{
            content:"";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -6.5px;
            height: 2px;
            background-color: $accent-color;
            transition: opacity 0.3s;
        }

        &:hover, &:focus{
            color: $accent-color-hover;

            &:after{
                opacity: 0;
            }
        }
    }


}

.rating-feedback-list{
    display: flex;

    &__item{
        background-color: $main-blue;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;

        @include breakpoint(small only) {
            width: 24px;
            height: 24px;
        }
    
        @include breakpoint(medium) {
            width: 32px;
            height: 32px;
        }

        &:not(:last-child){
            margin-right: 4px;
        }

        img{
            position: relative;
            top: -1px;
        }
    }

    &__text{
        letter-spacing: 1px;
        
        @include breakpoint(small only) {
            margin-bottom: 16px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 20px;
        }

        p{
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    
    &__name{
        font-weight: 600;
        font-size: 18px;
    }

    &_reverse{
        .rating-feedback-list__item{
            background-color: $light-color;
            margin-right: 0;

            svg{
                &>*{
                    fill: $main-blue;
                }
            }
        }
        
    }
}