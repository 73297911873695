.stock-block {
  background-color: $white;
  padding-bottom: 60px;

  &__date {
    @include breakpoint(small only) {
      margin-bottom: 30px;
    }

    @include breakpoint(medium) {
      margin-bottom: 60px;
    }
  }

  &__time {
    display: block;
    font-size: 18px;
    margin-bottom: 4px;
  }
}
