.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  background-color: $white;
  border-width: 2px 1px 0px 0px;
  border-style: solid;
  border-color: $secondary-color;
  border-radius: 12px;
  padding: 15px 40px 15px 16px !important;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 18.5px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background: url("../img/main-menu/close.svg") no-repeat center;
    background-size: 20px;
    border: none;
  }
}

.accordion-menu .is-accordion-submenu-parent[aria-expanded="true"] > a::after {
  transform: rotate(45deg) !important;
}

.dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
  @include breakpoint(large) {
    content: none;
  }
}

.dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
  @include breakpoint(large) {
    top: calc(100% + 16px);
  }
}

.submenu {
  padding-top: 16px;
  padding-bottom: 12px;
  padding-left: 22px;
}

.accordion-menu {
  li {
    .submenu {
      .is-accordion-submenu-parent {
        & > a {
          background-color: $light-color !important;

          @include breakpoint(small) {
            margin-left: -22px;
          }
        }
      }
    }
  }
}

.tabs {
  border: none;
  background: none;

  @include breakpoint(small only) {
    display: flex;
    overflow-x: auto;
    margin-bottom: 24px;
  }

  @include breakpoint(medium) {
    margin-bottom: 36px;
  }
}

.tabs-panel {
  padding: 0;
}

.tabs-content {
  border: none;
  background: none;
}

.tabs-title {
  @include breakpoint(small only) {
    margin-right: 30px;
  }

  @include breakpoint(medium) {
    margin-right: 40px;
  }

  &:last-child {
    margin-right: 0;
  }

  & > a {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    padding: 0;

    @include breakpoint(small only) {
      white-space: nowrap;
      padding-bottom: 30px;
    }

    @include breakpoint(medium) {
      font-size: 24px;
    }
  }
}

.tabs-title > a:hover,
.tabs-title > a:focus,
.tabs-title > a[aria-selected="true"] {
  background: none;
  color: rgba($black, 0.54);
}

.reveal {
  background: $light-brown;
  border-radius: 12px;
  border: none;

  @include breakpoint(medium) {
    width: 620px;
  }
}

.grid-container {
  &_narrow {
    max-width: 920px;
  }
}
