.breadcrumbs {
  margin: 0;

  @include breakpoint(small only) {
    padding: 16px 0 6px;
  }

  @include breakpoint(medium) {
    padding: 24px 0 14px;
  }

  &_padding-b {
    @include breakpoint(medium) {
      padding-bottom: 50px;
    }
  }

  &_white-bg {
    background-color: $white;
  }

  &__ul {
    margin: 0;
    padding: 0;
    overflow-x: auto;
    white-space: nowrap;
  }

  &__li {
    display: flex;
    align-items: center;
    list-style: none;
    position: relative;
    margin-bottom: 10px;

    &:not(:last-child) {
      &:after {
        content: "" !important;
        display: block;
        width: 20px;
        height: 1px;
        background-color: $light-grey;
        margin-left: 16px !important;
        margin-right: 16px !important;
      }
    }
  }

  &__link,
  &__text {
    color: $light-grey !important;
    text-transform: none;

    @include breakpoint(small only) {
      font-size: 16px;
    }

    @include breakpoint(medium) {
      font-size: 18px;
    }
  }
}
