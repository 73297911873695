.spoiler-block{    
    &__content{        
        @include breakpoint(small only) {
            max-height: 46px;
            overflow: hidden;
            margin-bottom: 20px;
            transition: 0.3s;
        }
    }

    &__btn{
        color: $accent-color;
        font-size: 16px;
        text-decoration: none;
        transition: 0.3s;

        &:hover, &:focus{
            text-decoration: none;
            color: $accent-color-hover;
        }

        @include breakpoint(small only) {
            display: block;
        }
        
        @include breakpoint(medium) {
            display: none;
        }
    }

    &_active{
        @include breakpoint(small only) {
            .spoiler-block__content{
                max-height: none;
            }

            .spoiler-block__btn{
                display: none;
            }
        }
    }
}