.stocks-block {
  background-color: $white;

  &__item {
    @include breakpoint(small only) {
      margin-bottom: 30px;
    }

    @include breakpoint(medium) {
      margin-bottom: 60px;
    }
  }
}
