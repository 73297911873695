.steps-work{
    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }
    
    &__list{
        display: flex;
        overflow-x: auto;
        padding-top: 20px;

        @include breakpoint(medium down) {
            padding-bottom: 16px;
        }

        &::-webkit-scrollbar-button {
            background-image:url('');
            background-repeat:no-repeat;
            width:2px;
            height:0px;
        }
    
        &::-webkit-scrollbar-track {
            background-color: rgba(26, 9, 0, 0.16);
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: rgba(26, 9, 0, 0.32);
        }
    
        &::-webkit-resizer{
            background-image:url('');
            background-repeat:no-repeat;
            width:2px;
            height:0px;
        }
    
        &::-webkit-scrollbar{
            height: 4px;
        }
    }

    &__item{
        position: relative;
        background-color: $white;
        border-width: 4px 2px 0px 0px;
        border-style: solid;
        border-color: rgba($black, 0.16);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex-shrink: 0;

        @include breakpoint(small only) {
            padding: 54px 16px 24px 16px;
            width: 250px;

            &:not(:last-child){
                margin-right: 24px;
            }
        }
        
        @include breakpoint(medium) {
            padding: 89px 30px 40px;
            width: 360px;
            min-height: 421px;
            
            &:not(:last-child){
                margin-right: 60px;
            }
        }

        @include breakpoint(large) {
            width: calc(33.333% - (120px/3));
        }
    }

    &__img{
        @include breakpoint(small only) {
            margin-bottom: 16px;
            height: 50px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 30px;
            height: 70px;
        }
    }

    &__name{
        display: block;
        color: $accent-color;
        text-transform: uppercase;
        line-height: 1.2;
        
        @include breakpoint(small only) {
            margin-bottom: 10px;
            font-size: 30px;
        }
    
        @include breakpoint(medium) {
            font-size: 40px;
            margin-bottom: 16px;
        }
    }

    &__step{
        position: absolute;

        @include breakpoint(small only) {
            max-width: 100px;
            top: -15px;
            right: 30px;
        }
    
        @include breakpoint(medium) {
            max-width: 180px;
            top: -20px;
            right: 38px;
        }
    }

    &__btn{
        @include breakpoint(small only) {
            margin-top: 24px;
        }
    
        @include breakpoint(medium) {
            margin-top: 30px;
        }
    }
}