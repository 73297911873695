.sale-slider{
    @include breakpoint(small only) {
        padding: 60px 0 30px;
    }

    @include breakpoint(medium) {
        padding: 80px 0 50px;
    }

    &__slider{
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: -20px;
        margin-right: -20px;

        .swiper-slide{
            opacity: 0;
        }

        .swiper-slide-active{
            opacity: 1;
        }
    }

    &__link{
        display: block;
        border-width: 4px 2px 0px 0px;
        border-style: solid;
        border-color: rgba($black, 0.16);
        border-radius: 32px;
        overflow: hidden;
        transition: box-shadow 0.3s;

        @include breakpoint(small only) {
            height: 288px;
        }

        &:hover{
            box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.2);
        }
    }

    &__img{
        @include breakpoint(small only) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__pagination{
        position: absolute;
        left: 50%;
        bottom: 38px;
        transform: translateX(-50%);        
        z-index: 100;
        background-color: rgba($black, 0.54);
        border-radius: 12px;
        display: flex;
        align-items: center;
        
        @include breakpoint(small only) {
            min-width: 192px;
            padding: 12px 57px;
        }

        @include breakpoint(medium) {
            min-width: 208px;
            padding: 13px 57px;
        }        
    }

    .swiper-wrapper{
        height: auto;
    }

    .swiper-pagination{
        position: unset;
        line-height: 1;
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .swiper-pagination-bullet{
        background-color:  $white;
        width: 12px;
        height: 12px;
        opacity: 1;

        &:not(:last-child){
            margin-right: 10px;
        }

        &-active{
            background-color: $accent-color;
            border: 0.5px solid light-grey;
        }
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled{
        opacity: 1;
    }

    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after,
    .swiper-button-next:after,
    .swiper-button-prev:after{
        content:"";
    }

    .swiper-button-prev,
    .swiper-button-next{
        background: url('../img/slider/arrow.svg') no-repeat center;
        background-size: 21px;
    }

    .swiper-button-prev{        
        @include breakpoint(small only) {
            left: 14px;
        }

        @include breakpoint(medium) {
            left: 20px;
        } 
    }

    .swiper-button-next{
        transform: rotate(-180deg);

        @include breakpoint(small only) {
            right: 14px;
        }

        @include breakpoint(medium) {
            right: 20px;
        } 
    }
    
}