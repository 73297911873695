.product-description {
  &__row {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;

    @include breakpoint(medium) {
      grid-gap: 28px;
    }

    @include breakpoint(large) {
      grid-gap: 60px;
      grid-template-columns: 360px 1fr;
    }
  }

  &__pic {
    width: 100%;
    height: 288px;
    display: inline-block;
    background-color: $white;
    border: 1px solid $light-brown;
    border-radius: 28px;
    overflow: hidden;

    @include breakpoint(medium) {
      height: 250px;
    }

    @include breakpoint(large) {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__intro {
    font-weight: 700;
  }

  &__content {
    font-size: 18px;
  }
}