.repair-block{
    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }

    &__title{   
        display: block;
        font-weight: 500;
        line-height: 144%;
        transition: color 0.3s;

        @include breakpoint(small only) {
            font-size: 18px;
            margin-bottom: 10px;
        }

        @include breakpoint(medium) {
           font-size: 24px;
           margin-bottom: 16px;
        }

        &:hover{
            color: $accent-color;
        }        
    }

    &__list{
        display: grid;

        @include breakpoint(medium down) {
            grid-gap: 30px;
            grid-template-columns: 1fr;
        }

        @include breakpoint(large) {
            grid-gap: 80px;
            grid-template-columns: 1fr 1fr;
        }
    }

    &__item{
        background-color: $white;
        display: flex;
        align-items: flex-start;
        transition: box-shadow 0.3s;
        border-radius: 32px;
        overflow: hidden;
        min-height: 284px;

        &:hover{
            box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.2);
        }
    }

    &__item-wrap{
        margin-bottom: 30px;
    }

    &__text{
        line-height: 144%;
    }

    &__item-container{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        
        @include breakpoint(small only) {
            padding: 30px 24px;
        }

        @include breakpoint(medium) {
            padding: 30px 40px 30px 50px;
        }
    }

    &__img{
        object-fit: cover;
        height: 100%;
        

        @include breakpoint(small only) {
            width: 60px;
        }

        @include breakpoint(large) {
            width: 200px;
        }
    }
}