.feedback-yandex-list{
    display: flex;
    overflow-x: auto;
    padding-bottom: 16px;

    &::-webkit-scrollbar-button {
        background-image:url('');
        background-repeat:no-repeat;
        width:2px;
        height:0px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(26, 9, 0, 0.16);
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(26, 9, 0, 0.32);
    }

    &::-webkit-resizer{
        background-image:url('');
        background-repeat:no-repeat;
        width:2px;
        height:0px;
    }

    &::-webkit-scrollbar{
        height: 4px;
    }

    &__item{
        flex-shrink: 0;

        @include breakpoint(small only) {
            width: 288px;

            &:not(:last-child){
                margin-right: 40px;
            }
        }
        
        @include breakpoint(medium only) {
            width: 360px;
        }

        @include breakpoint(medium) {
            &:not(:last-child){
                margin-right: 60px;
            }
        }

        @include breakpoint(large) {
            width: calc(33.3333% - 40px);
        }
    }

    &__item-rating{
        margin-bottom: 16px;
    }
}

.feedback-yandex{
    background-color: $white;

    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }

    &__rating{        
        @include breakpoint(small only) {
            margin-bottom: 32px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 50px;
        }
    }

    &__list{
        @include breakpoint(small only) {
            margin-bottom: 32px;
        }
        
        @include breakpoint(medium only) {
            margin-bottom: 42px;
        }

        @include breakpoint(large) {
            margin-bottom: 25px;
        }
    }
}
