.additional-services{
    background-color:  $white;

    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }

    &__item{
        @include breakpoint(small only) {
            margin-bottom: 24px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 30px;
        }
    }

    &__service-links{
        margin-top: 60px;
    }
}