.window-types {
  $blockMain: &;

  @include breakpoint(small only) {
    padding: 60px 0;
  }

  @include breakpoint(medium) {
    padding: 80px 0;
  }

  &__container {
    max-width: 78.75rem;
    margin: 0 auto;
    @include breakpoint(large) {
      padding-right: 1.875rem;
      padding-left: 1.875rem;
    }
  }

  &__title {
    font-weight: 700;
    line-height: 120%;
    margin: 0 1rem;
    @include breakpoint(small only) {
      margin-bottom: 32px;
      font-size: 30px;
    }

    @include breakpoint(medium) {
      margin-bottom: 57px;
      font-size: 40px;
    }

    @include breakpoint(large) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__list {
    @include breakpoint(small only) {
      padding: 0px 16px;
    }

    @include breakpoint(medium) {
      padding: 0px 0px 0px 16px;
    }

    @include breakpoint(large) {
      padding: 0px;
    }
  }

  &__item {
    width: 100%;
    background-color: $white;
    & + #{$blockMain}__item {
      margin: 30px 0px 0px;
    }

    @include breakpoint(small only) {
      padding: 24px 0px;
      border-radius: 32px;
    }

    @include breakpoint(medium) {
      padding: 60px 0px 60px 35px;
      border-radius: 32px 0px 0px 32px;
    }

    @include breakpoint(large) {
      padding: 60px 0px 60px 35px;
      border-radius: 32px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }

  &__col {
    &.--products {
      display: flex;
      overflow-x: auto;
    }

    @include breakpoint(small only) {
      width: 100%;
      padding: 0px 16px;
      & + #{$blockMain}__col {
        margin: 26px 0px 0px;
      }
    }

    @include breakpoint(medium) {
      & + #{$blockMain}__col {
        margin: 0px 0px 0px 26px;
      }

      &.--desc {
        width: 260px;
        min-width: 260px;
      }

      &.--products {
        padding-right: 35px;
        width: calc(100% - calc(260px + 26px));
      }
    }
  }

  &__name {
    font-size: 24px;
    font-weight: 500;
    margin: 0px 0px 16px;
    @include breakpoint(small only) {
      font-size: 18px;
    }
  }

  &__desc {
    font-size: 16px;
    margin: 16px 0px 0px;
    a {
      color: $accent-color;
      border: 0px;
      text-decoration: none;
    }
  }

  &__btn {
    margin: 33px 0px 0px;
    @include breakpoint(small only) {
      width: 100%;
    }
  }

  &-products {
    $products: &;

    display: flex;
    flex-grow: 1;
    padding-bottom: 20px;
    .swiper {
      &-scrollbar {
        bottom: 0px;
        @include breakpoint(medium) {
          display: none;
        }
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      width: auto;
      min-width: max-content;
    }

    &__main {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
    }

    &__footer {
      margin: 20px 0px 0px;
    }

    &__pic {
      width: auto;
      max-height: 280px;
      display: block;
    }

    &__name {
      font-size: 15px;
      font-weight: bold;
      white-space: nowrap;
      display: block;
      margin: 0px 0px 10px;
    }

    &__table {
      width: auto;
      margin: 0px;
      font-size: 15px;
      white-space: nowrap;
      border-collapse: unset;
      tr {
        & + tr {
          td {
            padding-top: 5px;
          }
        }

        &:nth-child(even) {
          background-color: transparent;
        }
      }

      th, td {
        padding: 0px;
      }

      td {
        & + td {
          padding-left: 20px;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }
  }
}
