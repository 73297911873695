.service-item-link{
    border: 2px solid rgba(26, 9, 0, 0.24);
    border-radius: 32px;
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: color 0.3s, box-shadow 0.3s;
    font-weight: 600;
    z-index: 5;

    &:after{
        content:"";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 38px;
        width: 16px;
        height: 16px;
        background: url('../img/service-item-link/arrow.svg') no-repeat right center;
        background-size: 32px;
        opacity: 0.24;
        transition: width 0.3s, opacity 0.3s;
    }

    &_accent{
        &:before{
            content:"";
            display: block;
            position: absolute;
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            height: 50px;
            background-color: rgba(255, 94, 0, 0.54);
            border-radius: 40px;
            z-index: -1;
        }
    }
    
    @include breakpoint(small only) {
        padding: 16px 60px 16px 24px;
        font-size: 16px;
    }

    @include breakpoint(medium) {
        padding: 19px 75px 19px 30px;
        font-size: 18px;
        min-height: 90px;
    }

    &:hover, &:focus{
        color: $accent-color;
        text-decoration: none;
        box-shadow: 0px 8px 24px rgba(255, 108, 59, 0.2);

        &:after{
            content:"";
            opacity: 1;
            width: 32px;
        }
    }
}