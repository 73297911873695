.service-item{
    border-radius: 32px;
    background-color: $light-color;
    border-style: solid;
    border-color: rgba($black, 0.16);
    transition: box-shadow 0.3s;

    @include breakpoint(small only) {
        padding: 24px 16px;
        border-width: 2px 2px 0px 0px;
    }

    @include breakpoint(medium) {
        display: flex;
        padding: 30px 16px;
        border-width: 4px 2px 0px 0px;
        align-items: flex-start;
        min-height: 283px;
    }

    &__title{
        a{
            transition: color 0.3s;
            border: none;
            
            &:after{
                content: none;
            }
        }
    }

    &__img{
        margin-right: 24px;
        margin-bottom: 24px;

        @include breakpoint(small only) {
            max-width: 40px;
        }
    }

    &__wrap-flex{
        @include breakpoint(medium) {
            flex-grow: 1;
        }

        @include breakpoint(large) {
            display: flex;
        }
    }

    &__middle{
        @include breakpoint(large) {
            width: 266px;
            flex-shrink: 0;
        }
    }

    &__content{
        @include breakpoint(large) {
            margin-left: 60px;
            flex-grow: 1;
        }
    }

    &__order{
        @include breakpoint(small only) {
            margin-bottom: 32px;
        }

        @include breakpoint(medium only) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
        }
    }

    &__price{
        display: block;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
            
        @include breakpoint(small only) {            
            margin-bottom: 32px;
        }

        @include breakpoint(large) {
            margin-bottom: 40px;
        }
    }

    &:hover{
        box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.2);

        .service-item__title{
            a{
                color: $accent-color;
            }
        }
    }
}