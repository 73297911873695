.departure{
    @include breakpoint(small only) {
        padding: 60px 0 44px;
    }

    @include breakpoint(medium) {
        padding: 80px 0 64px;
    }

    &__title{
        @include breakpoint(small only) {
            margin-bottom: 32px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 60px;
        }
    }
    
    &__item{        
        @include breakpoint(small only) {
            &:not(:last-child){
                margin-bottom: 44px;
            }
        }
    
        @include breakpoint(medium) {
            &:not(:last-child){
                margin-bottom: 65px;
            }
        }
    }
}