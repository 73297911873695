.service-link-list{
    display: grid;
    

    @include breakpoint(small only) {
        grid-template-columns: 1fr;
        grid-gap: 24px;
    }

    @include breakpoint(medium only) {
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(medium) {
        grid-gap: 30px 60px;
    }

    @include breakpoint(large) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}