.good-to-know {
  @include breakpoint(small only) {
    padding-bottom: 30px;
  }

  @include breakpoint(medium) {
    padding-bottom: 60px;
  }

  &_white-bg {
    background-color: $white;
  }

  &_padding {
    @include breakpoint(small only) {
      padding: 60px 0;
    }

    @include breakpoint(medium) {
      padding: 80px 0;
    }

    @include breakpoint(large) {
      padding-bottom: 138px;
    }
  }

  &__date {
    min-width: 116px;
    flex-shrink: 0;
    color: rgba(26, 9, 0, 0.54);

    @include breakpoint(small only) {
      margin-bottom: 16px;
      font-size: 16px;
    }

    @include breakpoint(medium) {
      font-size: 18px;
    }
  }

  &__middle {
    flex-grow: 1;
    text-align: right;

    @include breakpoint(small only) {
      margin-right: 16px;
      flex-shrink: 0;
      width: 60px;
    }

    @include breakpoint(medium) {
      margin-left: 34px;
      margin-right: 30px;
    }
  }

  &__right {
    display: flex;
    width: 100%;
  }

  &__content {
    @include breakpoint(small only) {
      font-size: 16px;
    }

    @include breakpoint(medium) {
      width: 420px;
    }

    @include breakpoint(large) {
      width: 600px;
      padding-right: 54px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;

    @include breakpoint(small only) {
      font-size: 16px;
    }

    @include breakpoint(medium) {
      font-size: 18px;
    }
  }

  &__item {
    border-width: 2px 1px 0px 0px;
    border-style: solid;
    border-color: rgba($black, 0.16);
    border-radius: 0px 32px 0px 0px;

    @include breakpoint(small only) {
      padding-top: 16px;
      padding-bottom: 16px;
      display: block;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @include breakpoint(medium) {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $accent-color;

      .good-to-know__title {
        color: $accent-color;
      }
    }
  }
}
