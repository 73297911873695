.main-information{
    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }

    &__text{
        background-color: $white;
        border-width: 4px 2px 0px 0px;
        border-style: solid;
        border-color: rgba($black, 0.32);
        border-radius: 32px;

        @include breakpoint(small only) {
            padding: 30px 16px;
        }
    
        @include breakpoint(medium) {
            padding: 60px 30px;
        }

        p{
            line-height: 1.2;
            max-width: 1000px;
            
            @include breakpoint(small only) {
                font-size: 24px;
            }

            @include breakpoint(medium) {
                font-size: 48px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}