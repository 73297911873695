.diagnostics-form{
    background-color: $light-brown;
    color: $white;

    a{
        color: $white;
    }


    
    &__title{
        color: $white;

        @include breakpoint(small only) {
            padding-top: 60px;
        }

        @include breakpoint(medium) {
            margin-bottom: 52px;
        }
    
        @include breakpoint(medium) {
            padding-top: 80px;
        }
    }

    &__small-text{
        color: $white;
        display: block;

        @include breakpoint(small only) {
            margin-bottom: 16px;
        }
    
        @include breakpoint(medium) {
            margin-bottom: 30px;
            font-weight: 600;
        }
    }

    &__ellips{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        @include breakpoint(small only) {
            padding-top: 60px;
            min-height: 440px;
        }
        
        @include breakpoint(medium down) {
            justify-content: center;
        }

        @include breakpoint(medium only) {
            padding-top: 16px;
        }
        
        @include breakpoint(medium) {
            min-height: 754px;
        }
    }

    &__img{
        max-width: 100%;
        height: auto;
        position: absolute;

        @include breakpoint(medium) {
            right: 50%;
            transform: translateX(50%);
        }
    }

    &__text{
        
        @include breakpoint(small only) {
            margin-bottom: 32px;
        }
        @include breakpoint(medium) {
            margin-bottom: 40px;
        }
    }

    &__form-man{
        position: relative;
        z-index: 2;
        max-height: 100%;

        @include breakpoint(small only) {
            bottom: 0;
        }
    }
}