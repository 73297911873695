.small-links-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    &__item{
        list-style: none;
        margin-bottom: 16px;

        &:not(:last-child){
            margin-right: 16px;
        }
    }
}