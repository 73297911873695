.stock-block-item {
  display: block;
  text-decoration: none;

  &__img {
    margin-bottom: 24px;

    @include breakpoint(large) {
      height: 360px;
    }
  }

  &__title {
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
    transition: 0.3s color;
  }

  &__time {
    display: block;
    font-size: 18px;
    margin-bottom: 4px;
  }

  &:hover {
    .stock-block-item__title {
      color: $accent-color-hover;
    }
  }

  &:focus {
    text-decoration: none;
  }
}
