.header {
  position: relative;
  background-color: $main-bg;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(small only) {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    @include breakpoint(medium only) {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    @include breakpoint(large) {
      border-bottom: 1px solid rgba(26, 9, 0, 0.16);
      padding-top: 23px;
      padding-bottom: 23px;
    }
  }

  &__wrap-flex {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__burger-line {
    display: block;
    position: absolute;
    height: 2px;
    width: 22px;
    background-color: $primary-color;
    border-radius: 25px;
    opacity: 1;
    left: 3px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &_first {
      top: 5px;
    }

    &_second {
      top: 13px;
    }

    &_third {
      top: 21px;
    }
  }

  &__burger {
    position: relative;
    cursor: pointer;
    height: 28px;
    width: 28px;
    display: block;
    margin-right: 12px;

    @include breakpoint(medium) {
      margin-right: 27px;
    }

    &_active {
      span {
        &:nth-child(1) {
          top: 13px;
          -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
        }

        &:nth-child(2) {
          -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
        }

        &:nth-child(3) {
          display: none;
        }
      }

      &_text {
        position: absolute;
        left: 43px;
        top: 18px;
        font-size: 13px;
      }
    }
  }

  &__logo {
    @include breakpoint(small) {
      margin-right: 15px;
    }

    @include breakpoint(large) {
      margin-right: 40px;
    }
  }

  &__marker {
    display: flex;
    align-items: flex-end;
    margin-top: 14px;
    font-size: 14px;
  }

  &__marker-img {
    margin-right: 10px;
  }

  &__main-btn {
    @include breakpoint(medium) {
      padding: 11px 12px;
    }

    @include breakpoint(large) {
      padding: 13px 24px;
    }
  }

  &__btn {
    margin-left: 16px;
    min-width: 99px;

    @include breakpoint(small only) {
      font-size: 11px;

      &:before {
        border-radius: 7px;
        top: -2px !important;
        right: -1px !important;
        bottom: 0px !important;
        left: 1px !important;
      }

      .secondary-btn__inner-wrap {
        padding: 5px 9px;
        border-radius: 7px;
      }
    }
  }

  &__messengers {
    @include breakpoint(medium) {
      margin-right: 16px;
    }

    @include breakpoint(large) {
      margin-right: 40px;
    }
  }
}

.messengers-header {
  display: flex;

  @include breakpoint(small only) {
    position: absolute;
    display: none;
    right: 0;
    top: calc(100% + 9px);
    background-color: $white;
    flex-direction: column;
    border-radius: 12px;
    min-height: 250px;
    min-width: 225px;
    padding: 24px 16px;
    z-index: 10;
  }

  @include breakpoint(medium) {
    flex-direction: row-reverse;
  }

  &_active {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @include breakpoint(medium) {
      &:not(:last-child) {
        margin-left: 12px;
      }
    }

    @media print, screen and (min-width: 768px) and (max-width: 840px) {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  &__item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    @include breakpoint(small only) {
      padding: 4px;
      background-color: $white;
      border-width: 1px 1px 0px 0px;
      border-style: solid;
      border-color: rgba($black, 0.16);
      border-radius: 8px;
    }

    @include breakpoint(medium) {
      width: 36px;
      height: 36px;
      padding: 6px;
      background-color: $white;
      border-width: 1px 1px 0px 0px;
      border-style: solid;
      border-color: rgba($black, 0.16);
      border-radius: 8px;
    }
  }

  &__item-info {
    margin-left: 10px;

    @include breakpoint(medium) {
      margin-left: 12px;
      display: none;
    }

    &_show {
      display: block;
    }
  }

  &__item-link {
    display: block;
    font-weight: 600;
    font-size: 16px;
  }

  &__item-small-text {
    display: block;
    font-size: 11px;
  }

  &__item-text {
    display: block;
    font-size: 14px;
  }
}

// для mobile menu на мобильнике start
.body-hide {
  overflow: hidden;
  position: relative;
}
html {
  &.active {
    height: auto;
    overflow: auto;
  }
}
// для mobile menu на мобильнике end

.main-menu {
  &_active {
    @include breakpoint(small only) {
      height: calc(100vh - 42px);
    }

    @include breakpoint(medium down) {
      position: absolute;
      left: 0;
      width: 100%;
      background-color: $main-bg;
      z-index: 900;
      overflow: auto;

      .main-menu__wrap {
        padding: 16px;
      }
    }

    @include breakpoint(medium only) {
      height: calc(100vh - 91px);
    }
  }

  &__wrap-ul {
    @include breakpoint(large) {
      position: absolute;
      background-color: $main-bg;
      width: 100%;
    }
  }

  &__wrap {
    @include breakpoint(large) {
      position: relative;
      padding-top: 12px;
    }

    & > ul {
      & > li {
        &:not(:last-child) {
          margin-right: 16px;
        }

        & > .main-menu__link-main {
          font-weight: 500;
        }
      }
    }
  }

  &__li {
    @include breakpoint(medium down) {
      &:not(:last-child) {
        padding-bottom: 12px;
      }
    }

    @include breakpoint(large) {
      padding-bottom: 12px;

      .main-menu__link-main {
        &:hover {
          color: $black;
          border-color: $main-bg;
        }
      }
    }
  }

  &__link {
    display: block;
    font-size: 16px;
    margin-bottom: 12px;
    padding: 0 !important;
    position: relative;
  }

  &__link-main {
    @include breakpoint(medium down) {
      background-color: $white;
      border-width: 2px 1px 0px 0px;
      border-style: solid;
      border-color: $secondary-color;
      border-radius: 12px;
      padding: 15px 40px 15px 16px !important;
      position: relative;
    }

    @include breakpoint(large) {
      padding: 14px 24px !important;
      border-width: 2px 1px 0px 0px;
      border-style: solid;
      border-radius: 12px;
      border-color: transparent;
      font-weight: 500;
      font-size: 16px;

      &:hover {
        background-color: $white !important;
        border-color: rgba($black, 0.16) !important;
      }
    }

    &_arrow {
      @include breakpoint(medium down) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 18.5px;
          top: calc(50% - 14px);
          width: 28px;
          height: 28px;
          background: url("../img/main-menu/arrow.svg") no-repeat center;
          background-size: 28px;
          border: none;
        }
      }
    }
  }

  &__text-ul {
    font-weight: 600;

    @include breakpoint(medium down) {
      font-size: 16px;
      color: $black !important;
      margin-bottom: 16px !important;
      background-color: transparent !important;
      padding: 0 !important;
    }

    @include breakpoint(large) {
      font-size: 18px;
    }
  }

  &__tab-menu-wrap {
    @include breakpoint(large) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 400;
      background-color: $main-bg;
      box-shadow: 0px 8px 24px rgba(200, 191, 186, 0.32);
      padding: 24px;
      padding-bottom: 100px;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -4px;
        height: 4px;
        background-color: $white;
      }

      &_active {
        display: flex;
      }
    }
  }

  &__tabs-link {
    @include breakpoint(large) {
      font-size: 16px !important;
      font-weight: 600 !important;
      padding: 13px 24px !important;
      width: 200px;
      border-width: 2px 1px 0px 0px;
      border-style: solid;
      border-color: transparent;
    }
  }

  &__tabs-title {
    @include breakpoint(large) {
      font-size: 16px;
      font-weight: 600;
      margin-right: 0;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &.is-active,
      &.main-menu__tabs-title_active {
        .main-menu__tabs-link {
          color: $black;
          background-color: $white;
          border-width: 2px 1px 0px 0px;
          border-style: solid;
          border-color: $accent-color;
          border-radius: 12px;
        }
      }
    }
  }

  &__tabs {
    @include breakpoint(large) {
      position: relative;
      padding-right: 52px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 24px;
        width: 4px;
        background-color: $white;
      }
    }
  }

  &__tabs-content {
    @include breakpoint(large) {
      border: none !important;
      width: 100%;
      padding-top: 20px;
    }
  }

  &__tab-title {
    display: block;
    margin-bottom: 50px;
    font-size: 18px;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 4px;
      width: 24px;
      bottom: -29px;
      left: -24px;
      background-color: $white;
    }

    a {
      padding: 0;
    }
  }

  &__tabs-ul {
    @include breakpoint(large) {
      display: block;
      column-count: 2;
      column-gap: 60px;
      max-width: 620px;

      .main-menu__link {
        line-height: 1.44;
        display: inline-block !important;

        &:after {
          content: "";
          display: block;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $accent-color-hover;
          opacity: 0;
        }

        &:hover,
        &:focus {
          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
