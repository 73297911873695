.advantage-item{
    background-color: $white;
    border-width: 2px 2px 0px 0px;
    border-style: solid;
    border-color: rgba($black, 0.16);
    border-radius: 32px;
    height: 100%;

    @include breakpoint(small only) {
        padding: 24px 16px;
        min-height: 237px;
    }

    @include breakpoint(medium) {
        padding: 30px;
        min-height: 292px;
    }
    
    &__title{
        display: block;
        font-weight: 600;

        @include breakpoint(small only) {
            font-size: 16px;
            margin-bottom: 10px;
        }
    
        @include breakpoint(medium) {
            font-size: 18px;
            margin-bottom: 12px;
        }
    }

    &__img{
        display: block;
        
        @include breakpoint(small only) {
            max-height: 40px;
            width: auto;
            margin-bottom: 24px;
            flex-shrink: 0;
        }
    
        @include breakpoint(medium) {
            max-height: 60px;
            margin-bottom: 30px;
        }
    }
}