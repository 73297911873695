.swiper-arrows {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 100px;
  background-color: #fff;
  &__btn {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: relative;
    margin: 0;
    width: 54px;
    height: 54px;
    border-radius: 100px;
    background-color: #c8bfba;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzMxIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDczMSA1MTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuMTgyMSAyODAuNTgyQy0zLjM5NDA0IDI2Ny4wMDYgLTMuMzk0MDQgMjQ0Ljk5NCAxMC4xODIxIDIzMS40MThMMjMxLjQxOCAxMC4xODIxQzI0NC45OTQgLTMuMzk0MDQgMjY3LjAwNiAtMy4zOTQwNCAyODAuNTgyIDEwLjE4MjFDMjk0LjE1OCAyMy43NTgzIDI5NC4xNTggNDUuNzY5NSAyODAuNTgyIDU5LjM0NTdMMTE4LjY5MSAyMjEuMjM2SDczMC4wNDJWMjkwLjc2NEgxMTguNjkxTDI4MC41ODIgNDUyLjY1NEMyOTQuMTU4IDQ2Ni4yMyAyOTQuMTU4IDQ4OC4yNDIgMjgwLjU4MiA1MDEuODE4QzI2Ny4wMDYgNTE1LjM5NCAyNDQuOTk0IDUxNS4zOTQgMjMxLjQxOCA1MDEuODE4TDEwLjE4MjEgMjgwLjU4MloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: center;
    &:after {
      display: none;
    }

    &.swiper-button-next {
      transform: rotate(180deg);
    }
  }

  &__count {
    color: #1a0900;
    font-size: 16px;
    text-align: center;
    min-width: 62px;
  }
}