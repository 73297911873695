.main-title {
    background-color: $secondary-color;
  
    @include breakpoint(small only) {
      padding: 60px 0;
    }
  
    @include breakpoint(medium) {
      padding: 90px 0;
    }
  
    &__title {
      font-weight: 700;
      color: $black;
      margin-bottom: 0;
  
      @include breakpoint(small only) {
        font-size: 32px;
      }
  
      @include breakpoint(medium) {
        font-size: 50px;
      }
    }
  }
  