body {
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.44;
    min-height: 100vh;
    background-color: $secondary-color;
  }
  
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    font-weight: 700;
    line-height: 1.2;
  }
  
  h1,
  .h1 {
    margin-bottom: 16px;
  }
  
  h2,
  .h2 {
    @include breakpoint(small) {
      margin-bottom: 32px;
    }
  
    @include breakpoint(medium) {
      margin-bottom: 60px;
    }
  }
  
  h3,
  .h3 {
    @include breakpoint(small) {
      margin-bottom: 24px;
    }
  
    @include breakpoint(medium) {
      margin-bottom: 32px;
    }
  }
  
  h4,
  .h4 {
    @include breakpoint(small) {
      margin-bottom: 16px;
    }
  
    @include breakpoint(medium) {
      margin-bottom: 24px;
    }
  }
  
  .logo {
    display: block;
    max-width: 86px;
    height: auto;
  
    @include breakpoint(medium) {
      max-width: 138px;
    }
  
    @include breakpoint(large) {
      max-width: 156px;
    }
  }
  p {
    @include breakpoint(medium) {
      font-size: 18px;
    }
  
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    a {
      text-decoration: none;
      position: relative;
      border-bottom: 2px solid $accent-color;
      transition: border-color 0.3s, color 0.3s;
  
      &:hover,
      &:focus {
        color: $accent-color-hover;
        border-bottom-color: transparent;
      }
    }
  }
  
  .main-btn {
    background-color: $accent-color;
    color: $light-color;
    border-radius: 12px;
    font-weight: 600;
    padding: 14px 24px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  
    &_high {
      @include breakpoint(small only) {
        padding: 14px 32px;
      }
  
      @include breakpoint(medium) {
        padding: 20px 40px;
      }
    }
  
    &:hover {
      background-color: $accent-color-hover;
      box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.25);
    }
  }
  
  .secondary-btn {
    position: relative;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -2px;
      right: -1px;
      bottom: 0px;
      left: 1px;
      border-radius: 12px;
      background-color: $accent-color;
      transition: 0.3s;
    }
  
    &__inner-wrap {
      position: relative;
      background-color: $white;
      display: block;
      border-radius: 12px;
      padding: 14px 22px;
    }
  
    &:hover {
      &:before {
        content: "";
        transform: translate(-2px, 2px);
        box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.25);
      }
    }
  }
  
  .no-js {
    @include breakpoint(medium down) {
      .main-menu__wrap {
        display: none;
      }
    }
  }
  
  .content-main-wrap {
    ul {
      margin-left: 0;
  
      li {
        position: relative;
        padding-left: 22px;
        list-style: none;
  
        @include breakpoint(small only) {
          font-size: 16px;
          margin-bottom: 10px;
        }
  
        @include breakpoint(medium) {
          font-size: 18px;
          margin-bottom: 16px;
        }
  
        &:before {
          content: "";
          display: block;
          background-color: $main-blue;
          border-radius: 2px;
          position: absolute;
          left: 0;
  
          @include breakpoint(small only) {
            width: 6px;
            height: 6px;
            top: 10px;
          }
  
          @include breakpoint(medium) {
            width: 8px;
            height: 8px;
            top: 9px;
          }
        }
  
        a {
          border-bottom: 2px solid $accent-color;
          transition: border-color 0.3s, color 0.3s;
  
          &:hover,
          &:focus {
            border-color: transparent;
            color: $accent-color-hover;
          }
        }
      }
    }
  
    h2,
    .h2 {
      @include breakpoint(small only) {
        margin-bottom: 24px;
      }
  
      @include breakpoint(medium) {
        margin-bottom: 40px;
      }
    }
  
    h3,
    .h3 {
      @include breakpoint(small only) {
        margin-bottom: 24px;
      }
  
      @include breakpoint(medium) {
        margin-bottom: 36px;
      }
    }
  
    h4,
    .h4 {
      @include breakpoint(small only) {
        margin-bottom: 24px;
      }
  
      @include breakpoint(medium) {
        margin-bottom: 32px;
      }
    }
  
    p {
      @include breakpoint(medium) {
        margin-bottom: 24px;
      }
    }
  }
  
  .price-block,
  .content-main-wrap {
    table {
      @include breakpoint(small only) {
        margin-bottom: 32px;
        display: block;
        overflow: auto;
      }
  
      @include breakpoint(medium) {
        margin-bottom: 40px;
      }
    }
  
    thead,
    tbody,
    tfoot {
      border: none;
    }
  
    tbody {
      tr {
        &:first-child {
          background-color: $secondary-color !important;
        }
      }
    }
  
    tr {
      background-color: $white !important;
    }
  
    th {
      text-align: left;
  
      @include breakpoint(small only) {
        font-size: 20px;
      }
  
      @include breakpoint(medium) {
        font-size: 24px;
      }
  
      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-bottom: 0;
      }
    }
  
    td,
    th {
      position: relative;
  
      @include breakpoint(small only) {
        padding: 12px;
        vertical-align: baseline;
        font-size: 16px;
  
        &:first-child {
          padding-left: 0;
        }
  
        &:last-child {
          padding-right: 12px;
        }
      }
  
      @include breakpoint(medium only) {
        &:last-child {
          padding-right: 0;
          width: 160px;
        }
      }
  
      @include breakpoint(medium) {
        padding: 13px;
        font-size: 18px;
  
        &:first-child {
          padding-left: 30px;
        }
      }
  
      @include breakpoint(large) {
        &:last-child {
          padding-right: 30px;
          width: 31%;
        }
      }
  
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 2px solid $light-grey;
      }
  
      &:last-child {
        border-radius: 0 0 32px 0;
        overflow: hidden;
        
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-width: 0px 1px 2px 0px;
          border-style: solid;
          border-color: $light-grey;
          border-radius: 0px 0px 32px 0px;
        }
      }
    }
  }
  
  input {
    &[type="text"],
    &[type="number"] {
      background-color: $white;
      border-radius: 12px;
  
      font-size: 16px;
      height: auto;
      margin-bottom: 30px;
  
      @include breakpoint(small only) {
        padding: 17px 16px;
      }
  
      @include breakpoint(medium) {
        padding: 32px 24px;
      }
  
      &::placeholder {
        color: rgba(26, 9, 0, 0.54);
  
        @include breakpoint(small only) {
          font-size: 12px;
        }
      }
    }
  }
  
  .main-small-info {
    display: block;
    padding: 8px 20px;
    background-color: $light-color;
    border: 1px solid rgba($black, 0.24);
    border-radius: 12px;
    font-size: 18px;
    text-decoration: none;
    transition: box-shadow 0.3s, border-color 0.3s;
  
    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $accent-color;
      border-width: 1px 1px 0px 0px;
      box-shadow: 0px 8px 24px rgba(255, 94, 0, 0.25);
      margin-left: 1px;
    }
  }
  