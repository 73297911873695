.get-a-guarantee{
    @include breakpoint(small only) {
        padding: 60px 0;
    }

    @include breakpoint(medium) {
        padding: 80px 0;
    }
    
    &__img-inner{
        @include breakpoint(small only) {
            margin-top: 8px;
            margin-bottom: 24px;
        }
    
        @include breakpoint(medium) {
            margin-top: 15px;
            margin-bottom: 32px;
        }
    }

    &__btn{
        @include breakpoint(small only) {
            margin-top: 24px;
            margin-bottom: 60px;
        }
    
        @include breakpoint(medium) {
            margin-top: 40px;
        }
    }

    &__title{
        @include breakpoint(small only) {
            margin-bottom: 24px;
        }

        @include breakpoint(medium) {
            margin-bottom: 32px;
        }

        @include breakpoint(large) {
            margin-top: 45px;
        }
    }

    &__img{
        @include breakpoint(medium only) {
            margin-top: 80px;
        }

        &_stiky{
            position: sticky;
            top: 20px;

            @include breakpoint(small only) {
                margin-top: 50px;
            }
        }
    }
}