.video {
  @include breakpoint(medium) {
    margin-top: 120px;
    margin-bottom: 40px;
  }

  @include breakpoint(large) {
    margin-top: 160px;
    margin-bottom: 80px;
  }

  &__content {
    flex-direction: column-reverse;
    gap: 29px;

    @include breakpoint(medium) {
      gap: 36px;
    }

    @include breakpoint(large) {
      flex-direction: row;
      gap: 0;
    }
  }

  &__bg-container {
    position: relative;

    aspect-ratio: 25 / 14;
    border-radius: 24px;
    overflow: hidden;

    @include breakpoint(medium) {
      aspect-ratio: 51 / 29;
    }

    @include breakpoint(large) {
      aspect-ratio: unset;
      height: 446px;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.36);
    }

    &.hide {
      display: none;
    }
  }

  &__video {
    width: 100%;
    aspect-ratio: 25 / 14;
    background-color: $black;
    border-radius: 24px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;

    @include breakpoint(medium) {
      aspect-ratio: 51 / 29;
    }

    @include breakpoint(large) {
      aspect-ratio: unset;
      height: 446px;
    }
  }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__play-button {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__play-icon {
    width: 50px;
    height: 50px;

    @include breakpoint(medium) {
      width: 90px;
      height: 90px;
    }
  }

  &__video {
    display: none;
    &.show {
      display: block;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 29px;

    @include breakpoint(medium) {
      gap: 34px;
    }

    @include breakpoint(large) {
      gap: 36px;
      padding-left: 30px;
    }
  }

  &__title {
    margin: 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 144%;
    letter-spacing: 0;

    @include breakpoint(medium) {
      font-size: 26px;
      line-height: 144%;
    }
  }

  &__advantages {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
  }

  &__advantage {
    display: flex;
    gap: 16px;
    list-style: none;
  }

  &__advantage-img {
    width: 60px;
    height: 60px;
    padding: 9px;

    border-right: 1px solid rgba(114, 114, 113, 0.5);
    border-top: 2px solid rgba(114, 114, 113, 0.5);
    border-radius: 10px;

    @include breakpoint(medium) {
      width: 84px;
      height: 84px;
      padding: 12px;
    }
  }

  &__advantage-text {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__advantage-title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 144%;
    letter-spacing: 0;
  }

  &__advantage-description {
    margin: 0;
    color: #262524;
    font-size: 16px;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: 0;
  }
}
